import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hey there! Welcome to my corner of the internet.
I’m Mukul, I’m a Software Engineer at Meta, having graduated with a Master’s degree at the University of Cambridge.`}</p>
    <p>{`My goal with my blog and `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UCpx1OLm5NJVfexaAYZ81VEQ/"
      }}>{`YouTube channel`}</a>{` is to provide free educational resources to share what I’ve learnt through my degree, side-projects and industry experience. Please don’t hesitate to `}<a parentName="p" {...{
        "href": "https://twitter.com/mukulrathi_"
      }}>{`reach out on Twitter`}</a>{`!`}</p>
    <h2 {...{
      "id": "interests",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#interests",
        "aria-label": "interests permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interests`}</h2>
    <p>{`I’m particularly interested in product-focused software engineering roles, which let me tap into my side interest in HCI and design. `}</p>
    <p>{`During my Master’s degree, I was particularly interested in `}<strong parentName="p">{`trustworthy machine learning`}</strong>{` - tackling issues like privacy, fairness, explainability and security of machine learning models. I first learnt about the intersection of machine learning and privacy through a `}<a parentName="p" {...{
        "href": "https://github.com/OpenMined/PySyft/pull/1566"
      }}>{`contribution to OpenMined`}</a>{`, and I made videos on research in this area on my `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UCpx1OLm5NJVfexaAYZ81VEQ/"
      }}>{`YouTube channel`}</a>{`.`}</p>
    <p>{`Previously I designed my own programming language `}<strong parentName="p">{`Bolt`}</strong>{`: a Java-style concurrent object-oriented language, whose type system prevents `}<em parentName="p">{`data races`}</em>{` (a class of concurrency bug also `}<a parentName="p" {...{
        "href": "https://doc.rust-lang.org/nomicon/races.html"
      }}>{`prevented by Rust’s type system`}</a>{`). Links to the `}<a parentName="p" {...{
        "href": "https://github.com/mukul-rathi/bolt"
      }}>{`repository`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://github.com/mukul-rathi/bolt-dissertation"
      }}>{`accompanying dissertation`}</a>{`.`}</p>
    <p>{`Some highlights:`}</p>
    <ul>
      <li parentName="ul">{`I was awarded the G-Research prize for the `}<strong parentName="li">{`best dissertation`}</strong>{` in my year at Cambridge.`}</li>
      <li parentName="ul">{`I was invited to give a talk about Bolt to the programming language group at Facebook during my internship.`}</li>
      <li parentName="ul">{`Bolt made it on `}<a parentName="li" {...{
          "href": "https://news.ycombinator.com/item?id=23122973"
        }}>{`the front page of Hacker News`}</a>{`. There’s a more technical discussion on `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/ProgrammingLanguages/comments/gg0cmd/bolt_a_new_language_with_datarace_freedom/"
        }}>{`r/ProgrammingLanguages`}</a></li>
      <li parentName="ul">{`I wrote a series of tutorial posts explaining each stage of the Bolt compiler, and the `}<a parentName="li" {...{
          "href": "/create-your-own-programming-language/llvm-ir-cpp-api-tutorial/"
        }}>{`LLVM tutorial`}</a>{` was received particular warmly, ranking `}<a parentName="li" {...{
          "href": "https://news.ycombinator.com/item?id=25539797"
        }}>{`#2 on Hacker News`}</a>{`, and receiving `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/programming/comments/kjjijf/a_complete_guide_to_llvm_for_programming_language/"
        }}>{`~590 upvotes on r/programming`}</a>{` and `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/ProgrammingLanguages/comments/kjuh0b/llvm_tutorial/"
        }}>{`~140 upvotes on r/ProgrammingLanguages`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "hackathons",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hackathons",
        "aria-label": "hackathons permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hackathons`}</h2>
    <p>{`I’ve enjoyed participating in hackathons during my time at university. Highlights include:`}</p>
    <ul>
      <li parentName="ul">{`BlackRock Prize for best eco-friendly hack at HackCambridge 2020. Our project `}<a parentName="li" {...{
          "href": "https://github.com/mukul-rathi/HackCambridge2020"
        }}>{`EcoScan`}</a>{` let you scan food items to calculate your carbon footprint. My hackathon teammate Michael `}<a parentName="li" {...{
          "href": "https://michaelng126.wordpress.com/2020/01/19/how-we-built-ecoscan-hackcambridge-2020/"
        }}>{`wrote a blog post`}</a>{`.`}</li>
      <li parentName="ul">{`Finalist HackCambridge 2019 (top 6 of 68) - our project `}<a parentName="li" {...{
          "href": "https://github.com/mukul-rathi/HackCambridge2019"
        }}>{`Out of the Vox`}</a>{` generated mind-maps directly from speech.`}</li>
      <li parentName="ul">{`Participating in an internal Facebook hackathon on the 3rd day of my first internship with 3 other interns. This was memorable because we were able to prototype a News Feed feature (details under NDA) despite a steep ramp-up (learning the language Hack and acquainting ourselves with the gigantic codebase!).`}</li>
    </ul>
    <p>{`One of my first hackathons was a Cambridge GameGig hackathon (making a `}<a parentName="p" {...{
        "href": "https://github.com/mukul-rathi/CambridgeGameGigHack"
      }}>{`retro game`}</a>{`) back in 2017. I enjoyed it so much I `}<strong parentName="p">{`started organising hackathons`}</strong>{` too!`}</p>
    <p>{`At university, I organised a few 12-hour hackathons run at the end of term - typically attended by ~50-80 students.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For `}<a parentName="p" {...{
            "href": "https://greenhack.hackersatcambridge.com/"
          }}>{`GreenHack`}</a>{` I was head of publicity, assisted participants with the Azure IoT kits provided and was part of the judging panel on the day.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I then became the lead organiser for subsequent hackathons: GameGig 2018 (One-button theme), and `}<a parentName="p" {...{
            "href": "https://game-gig-countdown.netlify.app/"
          }}>{`GameGig 2019 (Countdown theme)`}</a>{`.
This involved liasing with the sponsors, booking the venue and sorting out food/drink, and actually running and judging the events on the day.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      