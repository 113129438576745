/** @jsx jsx */
// @ts-ignore  (Flex import is fine)
import { jsx, Themed, Flex } from "theme-ui"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

//@ts-ignore
import ThemedTweet from "../components/themed-tweet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WhoAmI from "../components/mdx/about-me/who-am-i.mdx"
import { MDXProvider } from "@mdx-js/react"

const AboutMe = ({ location }: PageProps) => {
  const { avatar } = useStaticQuery(
    graphql`
      {
        avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
          childImageSharp {
            gatsbyImageData(width: 1000, layout: CONSTRAINED)
          }
        }
      }
    `
  )
  return (
    <Layout page="/about-me">
      <SEO
        title="About Mukul Rathi"
        path={location.pathname}
        pageType="other"
        description="A bit about me and the activities I get up to"
      />
      <main>
        <Themed.div sx={{ marginX: "3em" }}>
          <Themed.h1 sx={{ fontSize: 5 }}>About me</Themed.h1>
          <Flex sx={{ flexWrap: ["wrap-reverse", "nowrap"] }}>
            <Themed.div sx={{ flexGrow: 1, maxWidth: ["100%"] }}>
              <MDXProvider
                components={{
                  Tweet: ThemedTweet,
                  Flex,
                  h3: props => (
                    <Themed.h3 {...props} sx={{ marginTop: "1.5em" }} />
                  ),
                }}
              >
                <WhoAmI />
              </MDXProvider>
            </Themed.div>
            <Themed.div sx={{ flexGrow: 1, marginLeft: "1em" }}>
              <Themed.div
                sx={{
                  marginX: "auto",
                  maxWidth: ["300px", "100%"],
                  minWidth: ["50px", "300px"],
                  borderRadius: `100%`,
                }}
              >
                <GatsbyImage
                  image={avatar.childImageSharp.gatsbyImageData}
                  alt={"Mukul Rathi"}
                  imgStyle={{
                    borderRadius: `50%`,
                  }}
                />
              </Themed.div>
            </Themed.div>
          </Flex>
        </Themed.div>
      </main>
    </Layout>
  )
}

export default AboutMe
